export const getTagDiff = (oldTag, newTag) => {
  let tagDiff = {
    TagId: oldTag.TagId,
  };
  if (oldTag.TagLayerId) {
    delete tagDiff.TagId;
    tagDiff.TagLayerId = oldTag.TagLayerId;
  }
  for (const [key, value] of Object.entries(newTag)) {
    if (value === undefined) continue;
    if (value !== oldTag[key]) {
      tagDiff[key] = newTag[key];
    }
  }
  return tagDiff;
};

export const emptyTag = {
  TagName: 'New Tag',
  LoggingFrequency: 60,
  DynamicProperties:
    '{"flags":{"High Frequency Logged":false},"isHidden":false,"manualEntry":false,"syncToHub":false}',
};

export const leftColNames = ['TagName', 'TagIdentifier'];

export const rightColNames = ['Delete', 'Duplicate', 'Edit'];

export const leftColumns = [
  { title: 'Tag Name', width: 150, readonly: false, hasMenu: true },
  { title: 'Tag Identifier', width: 150, readonly: false, hasMenu: true },
];

export const rightColumns = [
  { title: 'Delete', width: 75, readonly: true },
  { title: 'Duplicate', width: 100, readonly: true },
  { title: 'Edit', width: 50, readonly: true },
];

export const gridTheme = {
  textHeader: 'white',
  bgHeader: '#017dba',
  accentColor: '#017dba',
  accentFg: 'black',
  bgHeaderHasFocus: '#017dba',
  bgHeaderHovered: 'gray',
};

export const optionalColumns = [
  'DisplayName',
  'UniversalDescriptor',
  'DeviceInfo',
  'ParentTagID',
  'MaxValue',
  'MinValue',
  'Gain',
  'Offset',
  'AlarmLoLo',
  'AlarmLo',
  'AlarmHiHi',
  'AlarmHi',
  'DisplayFormat',
  'UnitOfMeasure',
  'LoggingFrequency',
  'GroupName',
  'IsActive',
  'Description',
  'AssetNumber',
  'IsWriteable',
  'Transform',
  'ScanFrequency',
  'DynamicProperties',
  'SourceTypeName',
  'AlarmTypeName',
  'DataTypeName',
  'ModbusRegister',
  'ModbusIP',
  'ModbusPort',
  'ModbusSlaveId',
];

export const optionalColumnDefs = {
  DisplayName: {
    title: 'Display Name',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  UniversalDescriptor: {
    title: 'Universal Descriptor',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  DeviceInfo: {
    title: 'Device Info',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ParentTagID: {
    title: 'Parent Tag ID',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  MaxValue: {
    title: 'Max Value',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  MinValue: {
    title: 'Min Value',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  Gain: {
    title: 'Gain',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  Offset: {
    title: 'Offset',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmLoLo: {
    title: 'AlarmLoLo',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmLo: { title: 'AlarmLo', width: 150, readonly: false, hasMenu: true },
  AlarmHiHi: {
    title: 'AlarmHiHi',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmHi: { title: 'AlarmHi', width: 150, readonly: false, hasMenu: true },
  DisplayFormat: {
    title: 'DisplayFormat',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  UnitOfMeasure: {
    title: 'UnitOfMeasure',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  LoggingFrequency: {
    title: 'LoggingFrequency',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  GroupName: {
    title: 'GroupName',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  IsActive: {
    title: 'IsActive',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  Description: {
    title: 'Description',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AssetNumber: {
    title: 'AssetNumber',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  IsWriteable: {
    title: 'IsWriteable',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  Transform: {
    title: 'Transform',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ScanFrequency: {
    title: 'ScanFrequency',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  DynamicProperties: {
    title: 'DynamicProperties',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  SourceTypeName: {
    title: 'Source Type',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  AlarmTypeName: {
    title: 'Alarm Type',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ModbusRegister: {
    title: 'Modbus Register',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  DataTypeName: {
    title: 'Data Type',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusIP: {
    title: 'Modbus IP Address',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusPort: {
    title: 'Modbus Port',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusSlaveId: {
    title: 'Modbus Slave ID',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
};

export const getLookupIDs = function (
  internalNameArray,
  lookupName,
  lookupValues,
) {
  let lookupIdArray = [];
  for (let h = 0; h < internalNameArray.length; h++) {
    for (let i = 0; i < lookupValues.length; i++) {
      let lk = lookupValues[i];
      if (lk.LookupName === lookupName) {
        for (let j = 0; j < lk.Values.length; j++) {
          if (lk.Values[j].InternalName === internalNameArray[h]) {
            lookupIdArray.push(lk.Values[j].LookupValueId);
          }
        }
      }
    }
  }
  return lookupIdArray;
};

export const getLookupValues = function (
  valueName,
  LookupValueId,
  lookupValues,
) {
  let lookupIdArray = [];
  let id = parseInt(LookupValueId);
  for (let i = 0; i < lookupValues.length; i++) {
    if (lookupValues[i].LookupName === valueName) {
      for (let j = 0; j < lookupValues[i].Values.length; j++) {
        if (lookupValues[i].Values[j].LookupValueId === id) {
          return lookupValues[i].Values[j].InternalName;
        }
      }
    }
  }
  return lookupIdArray;
};

export const lookupNameToId = function (value, category, lookupValues) {
  return getLookupIDs([value], category, lookupValues)[0];
};

export const lookupIdToName = function (id, category, lookupValues) {
  let name = getLookupValues(category, id, lookupValues);
  if (Array.isArray(name)) {
    return '';
  } else {
    return name;
  }
};

export const validSourceTypes = [
  'ModbusTCP',
  'ModbusRTU232',
  'ModbusRTU485',
  'CDL',
  'J1939',
  'Internal',
  'FunctionLibrary',
  'SystemMetric',
  'HWIO',
];

export const defaultDeviceParams = {
  ModbusTCP:
    '{"connection":{"ip":"0.0.0.0","port":"0","slave_id":"0"},"regnum":"0000"}',
  ModbusRTU232:
    '{"connection":{"port_number":"0","slave_id":"0"},"regnum":"0000"}',
  ModbusRTU485:
    '{"connection":{"port_number":"0","slave_id":"0"},"regnum":"0000"}',
  CDL: '{"pid":"1","mask":"","signed":false,"mid":""}',
};
