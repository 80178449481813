import { fl106 } from './fl106';
import { fl107 } from './fl107';
import { fl109 } from './fl109';

const distroToScriptTemplate = {
  '3.1.0': fl106,
  '3.1.1': fl107,
  '3.2.1': fl109,
  '3.2.2': fl109,
  '3.2.3': fl109,
  '': {},
  null: {},
};

export const getScriptTemplatesByDistro = (distro) =>
  distroToScriptTemplate[distro];
