import { DataEditor, GridCellKind } from '@glideapps/glide-data-grid';
import { allCells } from '@glideapps/glide-data-grid-cells';
import { useCallback, useState, useEffect, useContext } from 'react';
import { useLayer } from 'react-laag';
import { LookupValueContext } from '../contexts/LookupValueContext';
import {
  gridTheme,
  leftColumns,
  rightColumns,
  leftColNames,
  rightColNames,
  optionalColumns,
  optionalColumnDefs,
  lookupNameToId,
  validSourceTypes,
  defaultDeviceParams,
} from '../helpers/Helpers';
import SelectDistro from './SelectDistro';
import _ from 'lodash';
import '@glideapps/glide-data-grid/dist/index.css';
import {
  Button,
  Divider,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
} from '@mui/material';
import {
  PlusOneOutlined,
  DeleteOutline,
  UndoOutlined,
} from '@mui/icons-material';
import { useColumnSort } from '@glideapps/glide-data-grid-source';

const TagGrid = ({
  data,
  setData,
  deleteRow,
  duplicateTag,
  openTagDialog,
  setSelectedTags,
  dirtyTags,
  setDirtyTags,
  tags,
  addNewEmptyTag,
  resetChanges,
  clearGrid,
  distro,
  setDistro,
}) => {
  const [gridSelection, setGridSelection] = useState(undefined);
  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem('visibleColumns')) || [],
  );
  const [columnStructure, setColumnStructure] = useState([
    ...leftColumns,
    ...rightColumns,
  ]);
  const [orderedCols, setOrderedColumns] = useState([]);

  const lookupValues = useContext(LookupValueContext);

  const generateDeviceInfoCell = useCallback(
    (row) => {
      if (!data || row >= data.length || !data[row].SourceTypeName) {
        return {
          allowOverlay: true,
          displayData: 'No Source',
          data: 'No Source',
          readonly: true,
          kind: GridCellKind.Text,
        };
      }
      const sourceTypeName = data[row].SourceTypeName;

      // CDL
      if (sourceTypeName === 'CDL') {
        try {
          const cdlInfo = JSON.parse(data[row].DeviceParams);
          const value = `${cdlInfo.mid ?? ''}/${cdlInfo.pid ?? ''};${cdlInfo.mask ?? ''}`;
          return {
            allowOverlay: true,
            displayData: value,
            data: value,
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        } catch (e) {
          return {
            allowOverlay: true,
            displayData: 'Invalid DeviceParams',
            data: 'Invalid DeviceParams',
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        }
      }
      // HWIO
      else if (sourceTypeName === 'HWIO') {
        const value = `${data[row].PortNumber ?? ''}/${data[row].Type ?? ''}${data[row].ChannelNumber ?? ''}`;
        return {
          allowOverlay: true,
          displayData: value,
          data: value,
          readonly: true,
          kind: GridCellKind.Text,
          allowWrapping: true,
        };
      }
      // ModbusRTU
      else if (
        sourceTypeName === 'ModbusRTU232' ||
        sourceTypeName === 'ModbusRTU485'
      ) {
        try {
          const modbusRTUInfo = JSON.parse(data[row].DeviceParams);
          const value = `${modbusRTUInfo.connection.slave_id ?? ''}/${modbusRTUInfo.regnum ?? ''};${modbusRTUInfo.connection.port_number ?? ''}`;
          return {
            allowOverlay: true,
            displayData: value,
            data: value,
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        } catch (e) {
          return {
            allowOverlay: true,
            displayData: 'Invalid DeviceParams',
            data: 'Invalid DeviceParams',
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        }
      }
      // ModbusTCP
      else if (sourceTypeName === 'ModbusTCP') {
        try {
          const modbusTCPInfo = JSON.parse(data[row].DeviceParams);
          const value = `${modbusTCPInfo.connection.slave_id ?? ''}/${modbusTCPInfo.regnum ?? ''};${modbusTCPInfo.connection.ip ?? ''}:${modbusTCPInfo.connection.port ?? ''}`;
          return {
            allowOverlay: true,
            displayData: value,
            data: value,
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        } catch (e) {
          return {
            allowOverlay: true,
            displayData: 'Invalid DeviceParams',
            data: 'Invalid DeviceParams',
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        }
      }
      // SystemMetric
      else if (sourceTypeName === 'SystemMetric') {
        return {
          allowOverlay: true,
          displayData: data[row].DeviceParams ?? '',
          data: data[row].DeviceParams ?? '',
          readonly: true,
          kind: GridCellKind.Text,
          allowWrapping: true,
        };
      }
      // FunctionLibrary
      else if (sourceTypeName === 'FunctionLibrary') {
        try {
          if (!data[row].Transform) {
            return {
              allowOverlay: true,
              displayData: 'Invalid Transform',
              data: 'Invalid Transform',
              readonly: true,
              kind: GridCellKind.Text,
              allowWrapping: true,
            };
          }
          if (data[row].Transform.startsWith('scriptTemplate')) {
            const parsedJSON = JSON.parse(data[row].Transform.substr(15));
            const funcName = parsedJSON.templateName ?? 'Custom Function';
            return {
              allowOverlay: true,
              displayData: funcName,
              data: funcName,
              readonly: true,
              kind: GridCellKind.Text,
              allowWrapping: true,
            };
          } else {
            return {
              allowOverlay: true,
              displayData: 'Custom Function',
              data: 'Custom Function',
              readonly: true,
              kind: GridCellKind.Text,
              allowWrapping: true,
            };
          }
        } catch (e) {
          return {
            allowOverlay: true,
            displayData: 'Invalid Transform',
            data: 'Invalid Transform',
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        }
      }
      // J1939
      else if (sourceTypeName === 'J1939') {
        try {
          const j1939info = JSON.parse(data[row].DeviceParams) ?? {};
          const value = `${j1939info.PGN}/${j1939info.StartBit}/${j1939info.NumBits}`;
          return {
            allowOverlay: true,
            displayData: value,
            data: value,
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        } catch (e) {
          return {
            allowOverlay: true,
            displayData: 'Invalid DeviceParams',
            data: 'Invalid DeviceParams',
            readonly: true,
            kind: GridCellKind.Text,
            allowWrapping: true,
          };
        }
      }
      // otherwise nothing
      return {
        allowOverlay: true,
        displayData: '',
        data: '',
        readonly: true,
        kind: GridCellKind.Text,
      };
    },
    [data],
  );

  const getTagCellData = useCallback(
    ([col, row]) => {
      if (!data || row >= data.length) {
        return {
          allowOverlay: true,
          displayData: 'Invalid Data',
          data: 'Invalid Data',
          readonly: true,
          kind: GridCellKind.Text,
        };
      }
      const colName = orderedCols[col];
      if (colName === 'Delete') {
        return {
          allowOverlay: false,
          kind: GridCellKind.Custom,
          copyData: '4',
          readonly: true,
          data: {
            kind: 'button-cell',
            backgroundColor: ['transparent', '#017dba'],
            color: ['accentColor', 'accentFg'],
            borderColor: '#017dba',
            borderRadius: 9,
            title: 'Delete',
            onClick: () => deleteRow(row),
          },
          themeOverride: {
            baseFontStyle: '700 12px',
          },
        };
      } else if (colName === 'Duplicate') {
        return {
          allowOverlay: false,
          kind: GridCellKind.Custom,
          copyData: '4',
          readonly: true,
          data: {
            kind: 'button-cell',
            backgroundColor: ['transparent', '#017dba'],
            color: ['accentColor', 'accentFg'],
            borderColor: '#017dba',
            borderRadius: 9,
            title: 'Duplicate',
            onClick: () => duplicateTag(row),
          },
          themeOverride: {
            baseFontStyle: '700 12px',
          },
        };
      } else if (colName === 'Edit') {
        return {
          allowOverlay: false,
          kind: GridCellKind.Custom,
          copyData: '4',
          readonly: true,
          data: {
            kind: 'button-cell',
            backgroundColor: ['transparent', '#017dba'],
            color: ['accentColor', 'accentFg'],
            borderColor: '#017dba',
            borderRadius: 9,
            title: 'Edit',
            onClick: () => openTagDialog(row),
          },
          themeOverride: {
            baseFontStyle: '700 12px',
          },
        };
      } else if (colName === 'Transform') {
        try {
          const value = data[row][colName] ?? '';
          let themeOverride;
          if (data[row][colName] && value != tags[row][colName]) {
            themeOverride = {
              bgCell: 'yellow',
            };
          }
          if (value.startsWith('scriptTemplate')) {
            const funcName =
              JSON.parse(value.substr(15)).templateName ?? 'Custom Function';
            return {
              allowOverlay: true,
              displayData: funcName,
              data: funcName,
              readonly: true,
              kind: GridCellKind.Text,
              themeOverride: themeOverride,
            };
          } else if (value.startsWith('table')) {
            return {
              allowOverlay: true,
              displayData: 'Table',
              data: 'Table',
              readonly: true,
              kind: GridCellKind.Text,
              themeOverride: themeOverride,
            };
          } else if (value.startsWith('script')) {
            return {
              allowOverlay: true,
              displayData: 'Custom Function',
              data: 'Custom Function',
              readonly: true,
              kind: GridCellKind.Text,
              themeOverride: themeOverride,
            };
          } else {
            return {
              allowOverlay: true,
              displayData: '',
              data: '',
              readonly: true,
              kind: GridCellKind.Text,
            };
          }
        } catch (e) {
          console.error(e);
          return {
            allowOverlay: true,
            displayData: '',
            data: '',
            readonly: true,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'DeviceInfo') {
        return generateDeviceInfoCell(row);
      } else if (colName === 'DynamicProperties') {
        let valueString = '';
        try {
          for (const [property, value] of Object.entries(
            JSON.parse(data[row][colName] ?? ''),
          )) {
            valueString += `${property}: ${JSON.stringify(value)}\n`;
          }
          return {
            allowOverlay: true,
            displayData: valueString,
            data: valueString,
            readonly: true,
            kind: GridCellKind.Text,
          };
        } catch (e) {
          console.error(e);
          return {
            allowOverlay: true,
            displayData: valueString,
            data: valueString,
            readonly: true,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'ParentTagID') {
        const parentTagId = data[row].ParentTagId;
        if (parentTagId) {
          const parentTagIdIndex = data.findIndex(
            (tag) => tag.TagIdentifier === data[row].ParentTagId,
          );
          const parentTagName = data[parentTagIdIndex].TagName;
          return {
            allowOverlay: true,
            displayData: parentTagName,
            data: parentTagName,
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          return {
            allowOverlay: true,
            displayData: 'No Parent Found',
            data: 'No Parent Found',
            readonly: true,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'DisplayFormat') {
        const value = data[row][colName] ?? '';
        if (window.Enbase.Common.Tag.ParseCodeLegend(value)) {
          return {
            allowOverlay: true,
            displayData: 'Code Legend',
            data: 'Code Legend',
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          return {
            allowOverlay: true,
            displayData: value,
            data: value,
            readonly: false,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'IsActive') {
        const value = data[row][colName] ?? false;
        return {
          allowOverlay: true,
          displayData: value,
          data: value,
          readonly: true,
          kind: GridCellKind.Boolean,
        };
      } else if (colName === 'AlarmTypeName') {
        const value = data[row][colName] ?? 'None';
        return {
          allowOverlay: true,
          displayData: value,
          data: value,
          readonly: true,
          kind: GridCellKind.Text,
        };
      } else if (colName === 'ModbusRegister') {
        const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
        if (!modbusTypes.includes(data[row]['SourceTypeName'])) {
          return {
            allowOverlay: true,
            displayData: 'Not a Modbus Tag',
            data: 'Not a Modbus Tag',
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          const modbusRegister = JSON.parse(data[row]['DeviceParams']).regnum;
          return {
            allowOverlay: true,
            displayData: modbusRegister,
            data: modbusRegister,
            readonly: false,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'ModbusIP') {
        if (data[row]['SourceTypeName'] !== 'ModbusTCP') {
          return {
            allowOverlay: true,
            displayData: 'Not a ModbusTCP Tag',
            data: 'Not a ModbusTCP Tag',
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          const modbusIP = JSON.parse(data[row]['DeviceParams']).connection.ip;
          return {
            allowOverlay: true,
            displayData: modbusIP,
            data: modbusIP,
            readonly: false,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'ModbusSlaveId') {
        const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
        if (!modbusTypes.includes(data[row]['SourceTypeName'])) {
          return {
            allowOverlay: true,
            displayData: 'Not a Modbus Tag',
            data: 'Not a Modbus Tag',
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          const modbusSlaveId = JSON.parse(data[row]['DeviceParams']).connection
            .slave_id;
          return {
            allowOverlay: true,
            displayData: modbusSlaveId,
            data: modbusSlaveId,
            readonly: false,
            kind: GridCellKind.Text,
          };
        }
      } else if (colName === 'ModbusPort') {
        const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
        const sourceTypeName = data[row]['SourceTypeName'];
        if (!modbusTypes.includes(sourceTypeName)) {
          return {
            allowOverlay: true,
            displayData: 'Not a Modbus Tag',
            data: 'Not a Modbus Tag',
            readonly: true,
            kind: GridCellKind.Text,
          };
        } else {
          const modbusPort =
            sourceTypeName === 'ModbusTCP'
              ? JSON.parse(data[row]['DeviceParams']).connection.port
              : JSON.parse(data[row]['DeviceParams']).connection.port_number;
          return {
            allowOverlay: true,
            displayData: modbusPort,
            data: modbusPort,
            readonly: false,
            kind: GridCellKind.Text,
          };
        }
      } else {
        const value = data[row][colName] ?? '';
        let themeOverride;
        if (value != tags[row][colName]) {
          themeOverride = {
            bgCell: 'yellow',
          };
        }
        return {
          allowOverlay: true,
          displayData: value.toString(),
          data: value.toString(),
          readonly: false,
          kind: GridCellKind.Text,
          themeOverride: themeOverride,
        };
      }
    },
    [
      data,
      deleteRow,
      duplicateTag,
      generateDeviceInfoCell,
      openTagDialog,
      orderedCols,
      tags,
    ],
  );

  const [sort, setSort] = useState(undefined);

  const sortProps = useColumnSort({
    columns: columnStructure,
    getCellContent: getTagCellData,
    rows: data.length,
    sort: sort,
  });

  const handleCellEdit = useCallback(
    (cell, newValue) => {
      let [col, row] = cell;
      row = sortProps.getOriginalIndex(row);
      const colName = orderedCols[col];
      let newData = [...data];

      if (colName === 'ModbusRegister') {
        if (
          newData[row]['SourceTypeName'] === 'ModbusTCP' ||
          newData[row]['SourceTypeName'].startsWith('ModbusRTU')
        ) {
          let parsedDeviceParams = {
            ...JSON.parse(newData[row]['DeviceParams']),
          };
          parsedDeviceParams.regnum = newValue.data;
          newData[row]['DeviceParams'] = JSON.stringify(parsedDeviceParams);
        } else {
          return;
        }
      } else if (colName === 'ModbusIP') {
        if (newData[row]['SourceTypeName'] === 'ModbusTCP') {
          let parsedDeviceParams = {
            ...JSON.parse(newData[row]['DeviceParams']),
          };
          parsedDeviceParams.connection.ip = newValue.data;
          newData[row]['DeviceParams'] = JSON.stringify(parsedDeviceParams);
        } else {
          return;
        }
      } else if (colName === 'ModbusPort') {
        if (newData[row]['SourceTypeName'] === 'ModbusTCP') {
          let parsedDeviceParams = {
            ...JSON.parse(newData[row]['DeviceParams']),
          };
          parsedDeviceParams.connection.port = newValue.data;
          newData[row]['DeviceParams'] = JSON.stringify(parsedDeviceParams);
        } else if (newData[row]['SourceTypeName'].startsWith('ModbusRTU')) {
          let parsedDeviceParams = {
            ...JSON.parse(newData[row]['DeviceParams']),
          };
          parsedDeviceParams.connection.port_number = newValue.data;
          newData[row]['DeviceParams'] = JSON.stringify(parsedDeviceParams);
        } else {
          return;
        }
      } else if (colName === 'ModbusSlaveId') {
        if (newData[row]['SourceTypeName'].startsWith('Modbus')) {
          let parsedDeviceParams = {
            ...JSON.parse(newData[row]['DeviceParams']),
          };
          parsedDeviceParams.connection.slave_id = newValue.data;
          newData[row]['DeviceParams'] = JSON.stringify(parsedDeviceParams);
        } else {
          return;
        }
      } else if (colName === 'SourceTypeName') {
        const sourceType = newValue.data;
        if (!lookupValues || !validSourceTypes.includes(sourceType)) {
          return;
        }
        newData[row][colName] = sourceType;
        newData[row]['SourceTypeId'] = lookupNameToId(
          sourceType,
          'TagSourceType',
          lookupValues,
        );
        if (defaultDeviceParams[sourceType]) {
          newData[row]['DeviceParams'] = defaultDeviceParams[sourceType];
        }
      } else if (colName === 'DataTypeName') {
        const allowed = [
          'BIT',
          'SINT8',
          'UINT8',
          'SINT16LE',
          'UINT16LE',
          'SINT16BE',
          'UINT16BE',
          'SINT32LE',
          'UINT32LE',
          'SINT32BE',
          'UINT32BE',
          'FLOAT32LE',
          'FLOAT32BE',
        ];
        const dataTypeName = newValue.data;
        if (allowed.includes(dataTypeName)) {
          newData[row]['DataTypeId'] = lookupNameToId(
            dataTypeName,
            'TagDataType',
            lookupValues,
          );
          newData[row][colName] = dataTypeName;
        } else {
          return;
        }
      } else {
        newData[row][colName] = newValue.data;
      }

      setData(newData);

      let isDirty = dirtyTags.includes(row);
      if (!_.isEqual(newData[row], tags[row])) {
        if (!isDirty) {
          let newDirtyTags = [...dirtyTags, row];
          setDirtyTags(newDirtyTags);
        }
      } else {
        if (isDirty) {
          let newDirtyTags = [...dirtyTags];
          _.remove(newDirtyTags, (index) => index === row);
          setDirtyTags(newDirtyTags);
        }
      }
    },
    [
      sortProps,
      orderedCols,
      data,
      setData,
      dirtyTags,
      tags,
      lookupValues,
      setDirtyTags,
    ],
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
    localStorage.setItem('visibleColumns', JSON.stringify(value));
  };

  useEffect(() => {
    const newCols = selectedColumns.map((colName) => {
      return optionalColumnDefs[colName];
    });
    setColumnStructure([...leftColumns, ...newCols, ...rightColumns]);
    setOrderedColumns([...leftColNames, ...selectedColumns, ...rightColNames]);
  }, [selectedColumns]);

  const [showColumnMenu, setShowColumnMenu] = useState(undefined);

  const onColumnMenuClick = useCallback((col, bounds) => {
    setShowColumnMenu({ col, bounds });
  }, []);

  const { renderLayer, layerProps } = useLayer({
    isOpen: showColumnMenu !== undefined,
    triggerOffset: 4,
    onOutsideClick: () => setShowColumnMenu(undefined),
    trigger: {
      getBounds: () => ({
        bottom:
          (showColumnMenu?.bounds.y ?? 0) +
          (showColumnMenu?.bounds.height ?? 0),
        height: showColumnMenu?.bounds.height ?? 0,
        left: showColumnMenu?.bounds.x ?? 0,
        right:
          (showColumnMenu?.bounds.y ?? 0) +
          (showColumnMenu?.bounds.height ?? 0),
        top: showColumnMenu?.bounds.y ?? 0,
        width: showColumnMenu?.bounds.width ?? 0,
      }),
    },
    placement: 'bottom-center',
    auto: true,
    possiblePlacements: ['bottom-start', 'bottom-end'],
  });

  return (
    <>
      <Stack direction={'row'} spacing={2} justifyContent={'right'}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="column-select-label">Columns</InputLabel>
          <Select
            labelId="column-select-label"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<OutlinedInput label="Columns" />}
            renderValue={(selected) => selected.join(', ')}
            size={'small'}
          >
            {optionalColumns.map((col, index) => (
              <MenuItem key={index} value={col}>
                <Checkbox checked={selectedColumns.indexOf(col) > -1} />
                <ListItemText primary={optionalColumnDefs[col].title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectDistro distro={distro} setDistro={setDistro} />
        <Button
          aria-label="Clear Grid"
          startIcon={<PlusOneOutlined />}
          onClick={() => {
            addNewEmptyTag(0);
          }}
          variant="contained"
        >
          Add New Tag
        </Button>
        <Button
          aria-label="Clear Grid"
          startIcon={<DeleteOutline />}
          onClick={() => clearGrid()}
          variant="contained"
        >
          Clear Grid
        </Button>
        <Button
          aria-label="Reset Changes"
          startIcon={<UndoOutlined />}
          onClick={() => resetChanges()}
          variant="contained"
        >
          Reset Changes
        </Button>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <DataEditor
        width={'100%'}
        height={300}
        columns={columnStructure}
        customRenderers={allCells}
        onPaste={true}
        rows={data.length}
        fillHandle={true}
        onCellEdited={handleCellEdit}
        getCellsForSelection={true}
        rowMarkers={{
          kind: 'checkbox',
          checkboxStyle: 'square',
        }}
        scaleToRem={true}
        theme={gridTheme}
        gridSelection={gridSelection}
        onGridSelectionChange={(newSelection) => {
          setGridSelection(newSelection);
          if (newSelection && newSelection.rows) {
            const arr = newSelection.rows
              .toArray()
              .map((i) => sortProps.getOriginalIndex(i));
            setSelectedTags(arr);
          }
        }}
        onColumnResize={(_col, newSize, colIndex, _newSizeWithGrow) => {
          let newColumnStructure = [...columnStructure];
          newColumnStructure[colIndex].width = newSize;
          setColumnStructure(newColumnStructure);
        }}
        onHeaderMenuClick={onColumnMenuClick}
        {...sortProps}
      />
      {showColumnMenu !== undefined &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              width: 100,
              padding: 2,
              borderRadius: 8,
              backgroundColor: 'white',
              boxShadow: '1px 1px 5px black',
            }}
          >
            <Stack direction={'column'} spacing={2}>
              <Button
                aria-label="Sort ASC"
                onClick={() => {
                  setSort(
                    {
                      column: columnStructure[showColumnMenu?.col],
                      direction: 'asc',
                      mode: 'smart',
                    } ?? undefined,
                  );
                }}
                variant="contained"
                size="small"
              >
                Sort ASC
              </Button>
              <Button
                aria-label="Sort DSC"
                onClick={() => {
                  setSort(
                    {
                      column: columnStructure[showColumnMenu?.col],
                      direction: 'desc',
                      mode: 'smart',
                    } ?? undefined,
                  );
                }}
                variant="contained"
                size="small"
              >
                Sort DSC
              </Button>
            </Stack>
          </div>,
        )}
    </>
  );
};

export default TagGrid;
