import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const SelectDistro = ({ distro, setDistro }) => {
  return (
    <>
      <FormControl>
        <InputLabel id="distro-label">Distro</InputLabel>
        <Select
          value={distro}
          onChange={(e) => {
            setDistro(e.target.value);
          }}
          size="small"
          labelId="distro-label"
          input={<OutlinedInput label="Distro" />}
        >
          <MenuItem value={'3.1.0'}>3.1.0</MenuItem>
          <MenuItem value={'3.1.1'}>3.1.1</MenuItem>
          <MenuItem value={'3.2.1'}>3.2.1</MenuItem>
          <MenuItem value={'3.2.2'}>3.2.2</MenuItem>
          <MenuItem value={'3.2.3'}>3.2.3</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectDistro;
